<template>
    <div v-if="hasToShowClassicHeader() || hasToShowEolHeader()"
         :class="criticity.class" id="informative-header-panel">
        <div v-if="configuration.actualState.preVisualize">
            <p>
                This is a pre visualization of the header you are configuring, users are not able to see this
                sentence<br/>
                {{ configuration.active ? 'active' : 'inactive' }} header
            </p>
            <v-divider/>
        </div>

        <v-row v-if="hasToShowClassicHeader()" no-gutters>
            <v-col cols=1 class="icon-panel">
                <span aria-hidden="true" :class="criticity.icon + ' header-icon-size'"/>
                <h1 id="informative-header-criticity">{{ criticity.iconTitle }}</h1>
            </v-col>
            <v-col cols=10 class="pt-3">
                <h1 id="informative-header-title"> {{ configuration.title }} </h1>
                <div id="informative-header-content">
                    <p v-html="configuration.content"/>
                </div>
            </v-col>
        </v-row>
        <v-row v-else-if="hasToShowEolHeader()" no-gutters>
            <v-col cols=1 class="icon-panel">
                <component :is="criticity.icon" :icon-size="100" :class="criticity.icon"/>
            </v-col>
            <v-col cols=10 class="pt-3">
                <h1 id="informative-header-title-eol"> {{ configuration.eol.title }} </h1>
                <div id="informative-header-content-eol">
                    <p v-html="configuration.eol.content"/>
                </div>
            </v-col>
        </v-row>
        <span v-if="!configuration.actualState.preVisualize && criticity.canClose" @click="close"
              class="btn-popup-close" id="informative-header-close-btn">
            <md-icon>close</md-icon>
        </span>
    </div>
</template>

<script>
import AdminTabsConstant from '../../constant/admin-tabs-constant';
import WsoIcon from "@/components/app-icon/wso-png.vue";

export default {
    name: "informative-header",
    data: function () {
        return {
            userWantClose: false,
            showHeader: false,
        }
    },
    computed: {
        configuration() {
            return this.$store.getters['headerConfiguration/getHeaderConfiguration'];
        },
        criticity() {

            if (this.hasToShowClassicHeader()) {

                const criticity = AdminTabsConstant.HEADER.CRITICITY_LEVEL.find(criticity => criticity.NAME === this.configuration.criticity);
                return {
                    class: criticity.NAME.toLowerCase(),
                    icon: criticity.icon,
                    iconTitle: criticity.NAME + " " + (criticity.NAME === AdminTabsConstant.HEADER.RESOLVED ? '' : ' alert'),
                    canClose: criticity.canClose
                }
            }

            const criticity = AdminTabsConstant.HEADER.CRITICITY_LEVEL_EOL;
            return {
                class: criticity.NAME.toLowerCase(),
                icon: criticity.icon,
                canClose: criticity.canClose
            }

        },
    },
    mounted: function () {
        this.$store.dispatch("headerConfiguration/getConfiguration");
    },
    methods: {
        close() {
            this.userWantClose = true;
        },
        hasToShowClassicHeader() {
            if (!this.configuration) return false;
            const hasToShowClassicHeader = this.configuration.actualState.preVisualize
                || (this.configuration.active && !this.userWantClose);
            return hasToShowClassicHeader;
        },
        hasToShowEolHeader() {
            return Boolean(this.configuration?.eol);
        },
    },
    components: {
        WsoIcon
    }
}
</script>

<style lang="scss">
#informative-header-panel {
    color: white;
    text-align: left;

    .icon-panel {
        align-self: center;
        text-align: center;

        h1 {
            text-transform: uppercase;
            font-weight: bold;
        }

        .header-icon-size {
            font-size: 2em;
        }
    }

    #informative-header-title {
        font-weight: bold;
        font-size: 2em;
    }

    #informative-header-content {
        font-size: 1.2em;

        a {
            color: white;
            font-weight: bold;
            text-decoration: underline;
        }
    }

    .btn-popup-close {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;

        i {
            color: white !important;
        }
    }

    &.minor {
        background-color: var(--vtmn-semantic-color_content-warning);
    }

    &.eol {
        background-color: #f7cf65;
        color: black;
        font-weight: bold;
        text-align: center;
        padding: 5px;
        margin: 10px;
        font-size: 1.5rem;
        position: relative;

        border-radius: 8px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
        border-width: 8px;
        border-style: solid;
        border-image-slice: 1;
        border-image-source: linear-gradient(90deg, white, #3b43ad);
        animation: border-dance 2s infinite linear;
    }

    @keyframes border-dance {
        0% {
            border-image-source: linear-gradient(90deg, white, #3b43ad);
        }
        100% {
            border-image-source: linear-gradient(90deg, #3b43ad, white);
        }
    }

    &.major {
        background-color: var(--vtmn-semantic-color_background-alert);
    }

    &.resolved {
        background-color: var(--vtmn-semantic-color_content-positive);
    }


    p {
        max-height: 120px;
        overflow: auto;
        overflow-wrap: break-word;
    }
}

#informative-header-title-eol {
    font-weight: bold;
    font-size: 0.9em;
    margin-top: 10px;
}

#informative-header-content-eol {
    font-size: 0.8em;
    line-height: 1.4;
}

#informative-header-content-eol a:first-of-type {
    color: white;
    font-weight: bold;
    text-decoration: none;
    background-color: #4193ce;
    padding: 8px 12px;
    border-radius: 4px;
    display: inline-block;
    transition: background-color 0.3s ease-in-out;
}

#informative-header-content-eol a:first-of-type:hover {
    background-color: #3070aa;
}

#informative-header-content-eol a:not(:first-of-type) {
    color: #4193ce;
    font-weight: normal;
    text-decoration: underline;
    background-color: transparent;
    padding: 0;
    border-radius: 0;
    display: inline;
    transition: none;
    margin-top: 0;
}

#informative-header-content-eol a:not(:first-of-type):hover {
    color: #3070aa;
}


</style>
